.fontbgproject{
    font-size: 50px;
    padding-bottom: 20px ;
}

.linebgproject{
    width: 100%;
    background-color: white;
    height: 3px;
    opacity: 100%;
    margin-bottom: 60px;
}

.fontcategory{
    font-size: 14px;
    font-weight: 400;
    line-height: 130.5%;
    letter-spacing: 0.06rem;
    color: #8F8F8F;
    
}

.fonttitle{
    font-size: 24px;
    margin-top: 15px;
    font-weight: 400;
    line-height: 130.5%;
    letter-spacing: 0.02rem;
    color: #8F8F8F;
}

.fontbodyproject{
    font-size: 15px;
    margin-top: 12px;
    font-weight: 300;
    color: #8F8F8F;
}

.paddingbody{
    padding-top: 80px;
}

.imagecol-9{
    height: 360px;
    width: 100%;
    object-fit: cover;
}

.imagecol-3{
    height: 360px;
    width: 100%;
    object-fit: cover;
}

.imagecol-6{
    height: 360px;
    width: 100%;
    object-fit: cover;
}

.margincardbody{
    margin-top: 40px;
}

.hiddendummy{
    display: block;
}

.marginbutton{
    margin-top: 127px;
}

.buttonview{
    width: 143px;
    height: 52px;
    border-radius: 40px;
    font-size: 13px;
    font-weight: normal;
    border: 1px solid black;
}


/* Large */
@media (max-width:1200px) {
    
    
}

/* Medium */
@media (max-width:768px) {
    .imagecol-9{
        height: 220px;
        width: 100%;
        object-fit: cover;
    }
    
    .imagecol-3{
        height: 220px;
        width: 100%;
        object-fit: cover;
    }
    
    .imagecol-6{
        height: 220px;
        width: 100%;
        object-fit: cover;
    }

    .fontcategory{
        font-size: 9px;
        font-weight: 400;
        line-height: 130.5%;
        letter-spacing: 0.06rem;
        color: #8F8F8F;
        
    }

    .fonttitle{
        font-size: 14px;
        margin-top: 8px;
        font-weight: 400;
        line-height: 130.5%;
        letter-spacing: 0.02rem;
        color: #8F8F8F;
    }
    
    .fontbodyproject{
        font-size: 9px;
        margin-top: 8px;
        font-weight: 400;
        width: 100%;
        color: #8F8F8F;
    }

    .margincardbody{
        margin-top: 20px;
    }

    .hiddendummy{
        display: none;
    }
    
    
}


/* Small */
@media (max-width:576px) {
    .fontbgproject{
        font-size: 21px;
        padding-bottom: 15px;
    }
    
    .linebgproject{
        height: 1px;
        margin-bottom: 25px;
    }

    .fontcategory{
        font-size: 12px;
        color: #8F8F8F;
    }

    .fonttitle{
        font-size: 16px;
        margin-top: 12px;
        color: #8F8F8F;
    }
    
    .fontbodyproject{
        font-size: 12px;
        color: #8F8F8F;
        font-weight: 400;

    }
    

    .paddingbody{
        padding-top: 40px;
    }

    .imagecol-9{
        height: 150px;
        width: 100%;
        object-fit: cover;
    }

    .imagecol-6{
        height: 208px;
        width: 100%;
        object-fit: cover;
    }

    .imagecol-3{
        height: 388px;
        width: 100%;
        object-fit: cover;
    }

    .margincardbody{
        margin-top: 30px;
    }
    
    .hiddendummy{
        display: none;
    }

    .marginbutton{
        margin-top: 80px;
    }
    
}

