:root{
    --primary: hsla(0, 0%, 0%,);
}

.bgimg{
    background: url("../Assets/project/test1.png");
    background-repeat: no-repeat;
   
    
}

.testbg{
    background: url("../Assets/project/test1.png");
    background-repeat: no-repeat;
}

.bgproject{
    background: url("../Assets//project/PROJECTS.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    margin: 0;
    
}

.fontlato{
    font-family: "Lato", sans-serif;
    font-size: 16px;
    font-weight: 900;
}

.background1 {
    background: url("../Assets/background-1.png");
    background-repeat: no-repeat;
}

.background2 {
    background: url("../Assets/background-2.JPG");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-position-y: -200px;
}

.backgroundhome {
    background: url("../Assets/fotoHome-1.png");
    background-repeat: no-repeat;
    background-size: cover
}

/* .backgroundproject1 {
    background : url("../Assets//project//project1.png")
} */

/* .backgroundproject2 {
    background : url("../Assets//project//project2.png")
} */

.backgroundstudio1 {
    background: url("../Assets//Studio//STUDIO.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    
}

.containerhome {
    display: grid;
    align-items:center;
    width: 100%;
    height: 100vh;
    background-color: salmon;
}

.containerhome ul{
    margin: auto;
    padding: 0;
}

.containerhome ul li{
    position: relative;
    list-style: none;
    color:black;
    font-size: 4rem;
    cursor: pointer;
}

.footercolor{
    color: #656565;
}

.navbar-toggler{
    background-color: white;
    border: none;
    padding: 10px 6px;
}

.slidercss{
    padding: 0;
    margin: 0;
}

.overlay{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .2)
}