.marginContact{
    margin-top: 218px;
}

.text-contactus{
    color: black;
    font-size: 42px;
    font-weight: 300;
    line-height: 63.21px;
    text-align: center;
}

.button-contact{
    margin-top: 50px;
    height: 52px;
    width: 124px;
    background-color: black;
    color: white;
    border-radius: 40px;
    font-weight: 400;
    font-size: 13px;
    border: none;
}

/* Body */

.textwhatsapp{
    margin-top: 145px;
    font-size: 18px;
    font-weight: 300;
    color: #8F8F8F;
    ;
}

.textWaNumber{
    margin-top: 10px;
    font-size: 28px;
    font-weight: 400;
    color: #8F8F8F;
}

.textWaNumber:hover{
    margin-top: 10px;
    font-size: 28px;
    font-weight: 700;
    color: #8F8F8F;
    text-decoration: none;
}


@media (max-width:768px) {
    .text-contactus{
        color: black;
        font-size: 32px;
        font-weight: 300;
        text-align: center;
        line-height: 150.5%;
        letter-spacing: -0.02em;
    }

    .textwhatsapp{
        margin-top: 125px;
        font-size: 14px;
        font-weight: 300;
        color: #8F8F8F;
    }

    .textWaNumber{
        margin-top: 10px;
        font-size: 18px;
        font-weight: 400;
        color: #8F8F8F;
    }
    
    .textWaNumber:hover{
        margin-top: 10px;
        font-size: 21px;
        font-weight: 700;
        color: #8F8F8F;
        text-decoration: none;
    }
}

@media (max-width:576px) {
    .text-contactus{
        color: black;
        font-size: 23px;
        font-weight: 300;
        text-align: center;
        line-height: 150.5%;
        letter-spacing: -0.05em;
    }

    .textwhatsapp{
        margin-top: 41px;
        font-size: 14px;
        font-weight: 300;
        color: #8F8F8F;
    }

    .textWaNumber{
        margin-top: 5px;
        font-size: 18px;
        font-weight: 400;
        color: #8F8F8F;
    }
    
    .textWaNumber:hover{
        margin-top: 5px;
        font-size: 18px;
        font-weight: 700;
        color: #8F8F8F;
        text-decoration: none;
    }
}

