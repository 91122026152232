.slider{
    display: flex;
}

.slider .items{
    height: 250px;
    width: 350px;
}

.slider .items img{
    width: 350px;
    height: 250px;
}

.slick-center img{
    opacity: 1;
    transition:ease-out .5s;
    transform: scaleY(0.8)
}

.slick-center {
    transform: scaleX(0.8);
}

.opacityimage {
    opacity: 0.3;
    transform: scaleY(0.7) scaleX(1.15)
}

.slick{
    display: flex;
    padding: 0;
    margin: 0;
}

.slick-dots{
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style-type: none;

}

.slick-dots li{
    margin: 50px 0.2rem;
}

.slick-dots button {
    display: block;
    width: 10px !important;
    height: 10px !important;
    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: red;
    border: 1px solid black !important;
    text-indent: -99999px;

   
}

.slick-dots li.slick-active button {
    background-color: black;
}


@media (max-width: 768px){
    .slick-dots button {
        display: block;
        width: 10px !important;
        height: 10px !important;
        padding: 0;
        transform: scale(0.8);
        border: none;
        border-radius: 100%;
        background-color: red;
        border: 1px solid black !important;
        text-indent: -99999px;
    }

    .slick-center {
        transform: scaleX(1.05) scaleY(1.05);
    }

    .opacityimage {
        opacity: 0.3;
        transform: scaleY(0.75) ScaleX(0.89)
    }

    .slick-dots li{
        margin: 15px 0.2rem;
    }
}

@media (max-width:576px) {
    .slick-center {
        transform: scaleY(1.4) scaleX(1.25);
    }

    .opacityimage {
        opacity: 0.3;
        transform: scaleY(0.85) scaleX(0.7)
    }

    .slick-dots button {
        display: block;
        width: 10px !important;
        height: 10px !important;
        padding: 0;
        transform: scale(0.9);
        border: none;
        border-radius: 100%;
        background-color: red;
        border: 1px solid black !important;
        text-indent: -99999px;
    }

    .slick-dots li{
        margin: -50px 0.2rem;
    }

}



/* .slick-active button{
    
} */

