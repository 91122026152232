.backgroundhover {
    background: url("../Assets/project/dummy.JPG")no-repeat center /cover;
    transition: ease-out 1s;
}

.hoverimage ul{
   display: flex;
   justify-content: start;
   align-items: center;
   position: relative;
}

.hoverimage ul li{
    margin: 10px;
    list-style: none; 
    width: 33%;
    background-color: transparent;
}

.hoversaya{
    text-decoration: none;
    background-image: linear-gradient(transparent, white);
    cursor: pointer;
    text-decoration: none;
}

.hoversaya:hover{
    background-image: linear-gradient(rgba(255, 255, 255, 0.753), white);
    cursor: pointer;
    text-decoration: none;
}   

.hoverimage ul li a{
    color: black;
    font-size: 2rem;
    font-weight: 700;
    text-decoration:none;
    transition: ease-in-out .3s;
}

.text-1{
    font-size: 16px;
    font-weight: bold;
    color: black;
    padding-top: 25px;
}

.text-2{
    font-size: 28px;
    font-weight: 300;
    color: black;
}

.rectanglehover{
    background-color:transparent;
    transition: ease-in-out .3s;
}

.rectanglehover:hover {
    background-color: white;
}