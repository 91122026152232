body{
    font-family: sans-serif;
    overflow-x: hidden;
}

.navbarnavbar{
    transition: all 1s;
}

.containerluar{
    background-color: brown;
}

.displayhomenavbar{
    display: none;
}

.navbarrights{
    display: none;
    font-size: 11px;
    font-weight: 300;
    margin-top: 145px;
}

.fontnavbar {
    color: black;
    font-weight: 700;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
}

.navbarbody{
    display: flex;
}

.logowidthnavbar {
    width: 100%;
}

.marginnavbarmiddle{
    margin: 0 45px;
}

/* Hamburger Menu */
.menu-toggle{
    display: none;
    flex-direction: column;
    height: 20px;
    justify-content: space-between;
    position: relative;
}

.menu-toggle input{
    position: absolute;
    width: 40px;
    height: 28px;
    left: -6px;
    top: -5px;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
}

.menu-toggle span{
    display: block;
    width: 28px;
    height: 2px;
    background-color: black;
    border-radius: 3px;
    transition: all .5s;
    margin: 5px;
}

.spanduaburger{
    width: 14px;
}


/* Hamburger menu animation */

.menu-toggle span:nth-child(2){
    transform-origin: 0 0;
}

.menu-toggle span:nth-child(4){
    transform-origin: 0 0;
}

.menu-toggle input:checked ~ span:nth-child(2){
   transform: rotate(45deg) translate(-2px, -5px);
}

.menu-toggle input:checked ~ span:nth-child(3){
    opacity: 0;
 }

.menu-toggle input:checked ~ span:nth-child(4){
   transform: rotate(-45deg) ;
}

nav{
    display: flex;
    justify-content: space-between;
    height: 100px;
    align-items: center;
}

nav ul{
    display: flex;
    list-style: none;
    width: 40%;
    justify-content: space-between;
  
}

nav ul li a{
    color: white;
    text-decoration: none;
    font-family: lato;
}

nav ul li a:hover{
    color: black;
    text-decoration: none;
}

@media (max-width: 768px){
    nav ul{
        width: 50%;
    }

    .displayhomenavbar{
        display: none;
    }


    .logowidthnavbar {
        width: 80%;
    }

    .fontnavbar {
        color: black;
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
    }

    .marginnavbarmiddle{
        margin: 0 25px;
    }
}

@media (max-width: 576px){
    nav ul{
        position: absolute;
        right: 0;
        top:0;
        background-color: salmon;
        height: 100%;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        z-index: -1;
        width: 100%;
        opacity: 0;
        display: none;
        transition: all 1s;
    }

    .displayhomenavbar{
        display: block;
    }

    .navbarnavbar {
        transition: all 1s;
    }

    .navbarbody{
       position: absolute;
       right: 0;
       top: 0;
       height: 100vh;
       background: url("../Assets/background-3.png");
       width: 100%;
       flex-direction: column;
       justify-content: space-evenly;
       align-items: center;
       z-index: -1;
       display:flex;
       transition: all 1s;
       padding: 200px 0;
       visibility:hidden;
       opacity: 0;
       
    }

    .active{
        opacity: 1;
        visibility:visible;
    }

    .logowidthnavbar {
        width: 70%;
    }

    nav ul.slide{
        opacity: 1;
    }

    .menu-toggle{
        display: block;
    }

    .marginnavbarmiddle{
        margin: 0;
    }

    .fontnavbar{
        font-weight: 300;
        font-size: 32px;
        text-transform:none;
        
    }

    .fontnavbar:hover {
        text-decoration: none;
    }


}