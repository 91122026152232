.bgimageresponsive{
    width: 100%;
    height: 100vh;
}

/* Footer */
.gambarfooter{
    width: 100%;
    height: 348px;
}

.logowidth{
    width: 100%;
    object-fit: cover;
}

.socialmedia1{
    display: block;
}

.socialmedia2{
    display: none;
}

.footer{
    margin-top: 175px;
    padding-top: 50px;
    height: 1500px;
}

.footer22{
    margin-top: 0px;
    padding-top: 20px;
    height: 200px;
}

.fontfooterheading{
    font-size: 12px;
    font-weight: 700;
    color: #8D8D8D;
    letter-spacing: 0.0125cm;
}

.fontsubheading-footer{
    font-size: 24px;
    color: #656565;
    font-weight: 300;
    letter-spacing: 0.02cm;
    line-height: 29px;
    text-decoration: none;
}

.fontsubheading-footer:hover{
    color: #656565;
    text-decoration: underline;
}



.margininput{
    margin-top: 0px;
}

.fontfooterbody{
    font-size: 38px;
    margin-top: 30px;
    font-weight: 300;
    letter-spacing: 0.025cm;
}

.background3 {
    background: url("../Assets/background-3.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.gambarfootersmall{
    display: none;
}

.gambarfooterlarge{
    display: block;
}


/* Page Contact us */
    .fontheadingcontact{
        font-size: 42px;
    }

    .fontsubheadingnumber{
        font-size: 24px;
        text-decoration: none;
    }

    .fontsubheadingnumber:hover{
        text-decoration: none;
    }



    .fontbodyphone{
        font-size: 18px;
    }

    .paddingcontact{
        padding-top: 245px;
    }

    .marginInTouch{
        margin-top: 0;
    }

    .marginphone{
        margin-top: 20px;
    }

    .marginleftbutton{
        margin-left: 30px;
    }

.footerdua{
    margin-top: 223px;
    padding:0px;
}

.fontservice-studio{
    font-size: 46px;
}

.rights{
    height: 145px;
    padding-top: 90px;
}

.follow{
    font-size: 24px;
    font-weight: 700;
}


/* Page Studio */
    .fontheadingstudio{
        font-size: 50px;
    }

    .fontsubheading-studio{
        font-size: 21px;
    }

    .fontbodystudio{
        font-size: 17px;
        font-weight: 300;
        letter-spacing: 0.025cm;
        margin-top: 30px;
        color: #8F8F8F;
    }

    .margintopcard {
        margin-top: 80px;
    }

    /* Card */
    .textcard-body{
        font-size: 17px;
        width: 90%;
        font-weight: 300;
    }

    .textcard-heading{
        font-size: 32px;
    }

    .garis{
        width: 100%;
        height: 1.5px;
        background-color: black;
    }

    .padding100{
        padding-top: 100px;
    }

    .margin80{
        margin-top: 80px;
    }

    .marginservice {
        margin-top: 120px;
    }

    .marginservicehome {
        margin-top: 80px;
    }

    .margincard{
        margin-top: 0;
    }

    .stylecontainer{
        display: flex;
        flex-direction: column;
        align-items: center;
    }



/* Large */
@media (max-width:1200px) {
    .textcard-body{
        width: 100%;
    }

    .gambarfooter{
        width: 100%;
        height: 348px;
    }
}


/* Medium */
@media (max-width:768px) {
    .fontheadingstudio{
        font-size: 28px;
    }

    .bgimageresponsive{
        width: 100%;
        height: 480px;
    }

    .padding100{
        padding-top: 60px;
    }

    .margintopcard {
        margin-top: 70px;
    }

    .marginservicehome {
        margin-top: 103px;
    }

    .margin80{
        margin-top: 30px;
    }

    .fontsubheading-studio{
        font-size: 16px;
    }

    .fontbodystudio{
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.025cm;
        margin-top: 30px;
        color: #8F8F8F;
    }

    .fontservice-studio{
        font-size: 30px;
    }

    .textcard-heading{
        font-size: 26px;
    }

    .textcard-body{
        font-size: 12px;
        width: 100%;
        font-weight: 400;
    }

    .paddingcontact{
        padding-top: 149px;
    }

    .fontheadingcontact{
        font-size: 28px;
    }

    .fontbodyphone{
        font-size: 14px;
    }

    .fontsubheadingnumber{
        font-size: 18px;
    }

    .margininput{
        margin-top: 40px;
    }

    .marginservice {
        margin-top: 60px;
    }

    .fontfooterheading{
        font-size: 10px;
        font-weight: 700;
        color: #8D8D8D;
        letter-spacing: 0.0125cm;
        margin-top: 42.5px;
    }
    
    .fontfooterbody{
        font-size: 18px;
        margin-top: 15px;
        font-weight: 300;
        letter-spacing: 0cm;
    }

    .gambarfooter{
        width: 100%;
        height: 300px;
    }

    .fontsubheading-footer{
        font-size: 18px;
        color: #656565;
        font-weight: 300;
        letter-spacing: 0.02cm;
        line-height: 29px;
        
    }

    .follow{
        font-size: 18px;
        font-weight: 700;
    }

    .logowidth{
        width: 80%;
    }

    .socialmedia1{
        display: none;
    }
    
    .socialmedia2{
        display: block;
    }

    .footerdua{
        margin-top: 50px;
        padding:0px;
    }

    .rights{
        margin-top: 0px;
    
    }

    .footer{
        margin-top: 110px;
        padding-top: 0px;
        height: 1054px;
    }
    

}


/* Small */
@media (max-width:576px) {
    .bgimageresponsive{
        width: 100%;
        height: 300px;
    }

    .fontheadingstudio{
        font-size: 26px;
    }

    .gambarfooter{
        width: 100%;
        height: 246px;
    }

    .marginleftbutton{
        margin-left: 0px;
    }

    .margintopcard {
        margin-top: 80px;
    }

    .fontheading{
        font-size: 26px;
    }

    .socialmedia1{
        display: block;
    }
    
    .socialmedia2{
        display: none;
    }

    .fontsubheading{
        font-size: 21px;
    }

    .fontbodystudio{
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.025cm;
        margin-top: 30px;
        color: #8F8F8F;
    }

    .fontsubheading-studio{
        font-size: 18px;
    }

    .fontservice-studio{
        font-size: 40px;
    }

    .padding100{
        padding-top: 30px;
    }
    
    .margin80{
        margin-top: 30px;
    }
    

    .textcard-body{
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0.025cm;
    }
    
    .textcard-heading{
        font-size: 28px;
    }

    .garis{
        width: 100%;
        height: 1px;
        background-color: black;
    }
    

    .btn-1{
        background-color: black;
        color: white;
    }

    .footer{
        margin-top: 110px;
        padding-top: 0px;
        height: 700px;
    }

    .footerdua{
        margin-top: 40px;
        padding:0px;
    }
    
    .rights{
        margin-top: 75px;
    
    }

    .follow{
        font-size: 24px;
        font-weight: 500;
    }

    .marginservice{
        margin-top: 80px;
    }

    .marginservicehome{
        margin-top: 80px;
    }

    .margincard{
        margin-top: 70px;
    }

    .paddingcontact{
        padding-top: 140px;
    }

    .marginInTouch{
        margin-top: 78px;
    }

    .marginphone{
        margin-top: 25px;
    }

    .fontfooterbody{
        font-size: 26px;
        margin-top: 20px;
        font-weight: 300;
        letter-spacing: 0.025cm;
    }

    .fontfooterheading{
        font-size: 12px;
        font-weight: 700;
        color: #8D8D8D;
        letter-spacing: 0.0125cm;
        margin-top: 70px;
    }

    .rights{
        margin-top: 0px;
        padding-top: 40px;
    
    }

    .gambarfootersmall{
        display: block;
    }

    .gambarfooterlarge{
        display: none;
    }

    .margininput{
        margin-top: 0px;
    }

    .fontsubheading-footer{
        font-size: 21px;
        color: #656565;
        font-weight: 300;
        letter-spacing: 0.02cm;
        line-height: 29px;
    }

}