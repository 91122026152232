.slickDots-home{
    display: flex !important;
    justify-content: start;
    list-style: none;
    margin: 15px 140px;
    padding: 0;
    position: relative;
    height: 0px;
    z-index: 1;
}

.slick-slider{
    padding: 0;
    margin: 0;
    position: relative;
}

.slickDots-home li{
    margin: 0px;
    position: relative;
    bottom: 80px;
    width: 80%;
}

.slickDots-home button{
    width: 100%;
    height: 1px;
    border: none;
    text-decoration: none;
    opacity: 0.5;
    color: blue;
    background-color: white;
    text-indent: -999999px;
    transition: all 0.5s;
}

.slickDots-home li.slick-active button{
    opacity: 1;
    transform: scaleY(1.5);
}

li.slick-active:hover + .textDots{
    color: red;
}


.slick-slider .prev{
    position: absolute;
    width: 60px;
    z-index: 10;
    text-align: center;
    top: 450px;
    left: 0;
    cursor: pointer;
    color: white;
    transform: scale(2.5);
}

.slick-slider .next{
    position: absolute;
    z-index: 10;
    width: 60px;
    text-align: center;
    bottom: 450px;
    right: 0;
    color: white;
    transform: scale(2.5);
    cursor: pointer;
}

.opacitySlider{
    width: 100%;
    height: 20vh;
    background-image: linear-gradient(transparent,black);
    position: absolute;
    left: 0;
    opacity:0.9;
    z-index: 0;
    bottom: 0;
}

/* Arrow Prev */

.ularrowPrev{
    display: flex;
    top: 45vh;
    left: 5%;
    padding-left: 0;
    position: absolute;
    cursor: pointer;
    z-index: 1;
}

.ularrowPrev li{
    list-style: none;
    width: 50px;
    height: 50px;
    background: transparent;
    border-radius: 50%;
    transition: ease-in-out 0.5s;
    position: relative;
    border: 1px solid #8F8F8F;
}

.ularrowPrev li span {
    position: absolute;
    width: 10px;
    height: 10px;
    border: 1px solid #8F8F8F;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ularrowPrev li.arrowPrev span{
    top: 50%;
    right: 35%;
    transform: translateY(-50%) rotate(135deg);
    border-top: none;
    border-left: none;
}

.ularrowPrev li span:hover{
    border: 1px solid black;
    border-top: none;
    border-left: none;
}

.ularrowPrev li:hover{
    background: white;
    border: none;
    opacity: 0.85;
}

/* Arrow Next */

.ularrowNext{
    display: flex;
    top: 45vh ;
    right: 5%;
    padding-right: 0;
    position: absolute;
    cursor: pointer;
    z-index: 1;
}

.ularrowNext li{
    list-style: none;
    width: 50px;
    height: 50px;
    background: transparent;
    border-radius: 50%;
    transition: ease-in-out 0.5s;
    position: relative;
    border: 1px solid #8F8F8F;
}

.ularrowNext li span {
    position: absolute;
    width: 10px;
    height: 10px;
    border: 1px solid #8F8F8F;
    transition: .5s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ularrowNext li.arrowNext span{
    top: 50%;
    left: 35%;
    transform: translateY(-50%) rotate(-45deg);
    border-top: none;
    border-left: none;
}

.ularrowNext li span:hover{
    border: 1px solid black;
    border-top: none;
    border-left: none;
    
}

.ularrowNext li:hover{
    background: white;
    border: none;
    opacity: 0.85;
}

/* Slider Dots */

.sliderDots {
    display: flex;
    position: absolute;
    top: 88vh;
    justify-content:center;
    width: 100%;
    z-index: 3
}

.textDots {
    font-size: 18px;
    font-weight: 400;
    color: white;
    display: flex;
    width: 100%;
    margin: -2vh 144px;
    transition: all 0.3s;
    justify-content: start;
}

.satusatu{
    opacity: 1;
}

.textDots2 {
    font-size: 18px;
    font-weight: 400;
    color: white;
    opacity: 1;
    display: flex;
    width: 80%;
    justify-content: space-around;
    transition: all 0.3s;
}





@media (max-width:768px){

    /* Slider Dots */

    .sliderDots {
        display: flex;
        position: absolute;
        top: 405px;
        justify-content:center;
        width: 100%;
        z-index: 3;
    }

    .textDots {
        font-size: 15px;
        font-weight: 400;
        color: white;
        opacity: 1;
        display: flex;
        width: 100%;
        margin: 0 75px;
        transition: all 0.3s;
        justify-content: start;
    }

    .textDots2 {
        font-size: 18px;
        font-weight: 400;
        color: white;
        opacity: 1;
        display: flex;
        width: 80%;
        justify-content: space-around;
        transition: all 0.3s;
    }

    .slick-slider .prev{
        position: absolute;
        width: 60px;
        z-index: 10;
        text-align: center;
        top: 240px;
        left: 0;
        cursor: pointer;
        color: white;
        transform: scale(2);
    }

    .slickDots-home button {
        width: 100%;
        height: 1px;
        border: none;
        text-decoration: none;
        opacity: 0.5;
        color: blue;
        background-color: white;
        text-indent: -999999px;
        transition: all 0.5s;
    }
    
    .slickDots-home li.slick-active button{
        opacity: 1;
        transform: scaleY(1.5);
    }
    
    .slick-slider .next{
        position: absolute;
        z-index: 10;
        width: 60px;
        text-align: center;
        bottom: 240px;
        right: 0;
        color: white;
        transform: scale(2);
        cursor: pointer;
    }

    .slickDots-home{
        display: flex !important;
        justify-content: start;
        list-style: none;
        margin: 0 75px;
        padding: 0;
        position: relative;
        top: 30px;
    }

    .ularrowPrev{
        top: 43%;
    }

    .ularrowNext{
        top: 43%;
    }

    .ularrowPrev li{
        transform: scale(0.7);
    }

    .ularrowPrev li span:hover{
        border: 1px solid #8F8F8F;
        border-top: none;
        border-left: none;
    }
    
    .ularrowPrev li:hover{
        background: transparent;
        border: 1px solid #8F8F8F;
    }
   
    .ularrowNext li{
        transform: scale(0.7);
    }

    .ularrowNext li span:hover{
        border: 1px solid #8F8F8F;
        border-top: none;
        border-left: none;
    }
    
    .ularrowNext li:hover{
        background: transparent;
        border: 1px solid #8F8F8F;
    }

    .opacitySlider{
        width: 100%;
        height: 100px;
        background-image: linear-gradient(transparent, black);
        position: absolute;
        opacity: 1;
        top: 380px;
        z-index: 1;
    }

 

}

@media (max-width:576px){

    .sliderDots {
        display: flex;
        position: absolute;
        top: 240px;
        justify-content:center;
        width: 100%;
        z-index: 3;
    }

    .textDots {
        font-size: 12px;
        font-weight: 400;
        color: white;
        opacity: 1;
        display: flex;
        width: 100%;
        margin: 0 30px;
        transition: all 0.3s;
        justify-content: start;
    }

    .textDots2 {
        font-size: 18px;
        font-weight: 400;
        color: white;
        opacity: 1;
        display: flex;
        width: 80%;
        justify-content: space-around;
        transition: all 0.3s;
    }
    
    .slick-slider .prev{
        position: absolute;
        width: 60px;
        z-index: 10;
        text-align: center;
        top: 150px;
        left: 0;
        cursor: pointer;
        color: white;
        transform: scale(2);
    }

    .slickDots-home button {
        width: 100%;
        height: 1px;
        border: none;
        text-decoration: none;
        opacity: 0.5;
        color: blue;
        background-color: white;
        text-indent: -999999px;
        transition: all 0.5s;
        transform: scaleY(0.5);
    }
    
    .slickDots-home li.slick-active button{
        opacity: 1;
        transform: scaleY(0.75);
    }
    
    .slick-slider .next{
        position: absolute;
        z-index: 10;
        width: 60px;
        text-align: center;
        bottom: 150px;
        right: 0;
        color: white;
        transform: scale(2);
        cursor: pointer;
    }

    .slickDots-home{
        display: flex !important;
        justify-content: start;
        list-style: none;
        margin: 0 30px;
        padding: 0;
        position: relative;
        background-color: red;
        top:40px;
        z-index: 1;
    }

    .slickDots-home button {
        width: 100%;
        height: 3px;
        border: none;
        text-decoration: none;
        opacity: 0.5;
        color: blue;
        background-color: white;
        text-indent: -999999px; 
    }

    .ularrowPrev{
        top: 40%;
    }

    .ularrowPrev li span:hover{
        border: 1px solid #8F8F8F;
        border-top: none;
        border-left: none;
    }
    
    .ularrowPrev li:hover{
        background: transparent;
        border: 1px solid #8F8F8F;
    }

    .ularrowNext li span:hover{
        border: 1px solid #8F8F8F;
        border-top: none;
        border-left: none;
    }
    
    .ularrowNext li:hover{
        background: transparent;
        border: 1px solid #8F8F8F;
    }

    .ularrowNext{
        top: 40%
    }

    .ularrowPrev li{
        transform: scale(0.6);
    }

   
    .ularrowNext li{
        transform: scale(0.6);
    }

    .opacitySlider{
        width: 100%;
        height: 70px;
        background-image: linear-gradient(transparent, black);
        position: absolute;
        opacity: 1;
        top: 230px;
        z-index: 1
    }

    .mobileHidden{
        display: none;
    }
}