.bgsliderhome{
    width: 100%;
    height: 100vh;
    object-fit: cover;
    z-index: -2;
}

.hoverHomeMobile {
    display: none;
}

.bgSliderMobile-home{
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.backgroundSliderMobile-homeOpenaire{
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(133, 133, 133, 0),rgba(133, 133, 133, 0), rgb(224, 224, 224));
    top: 0px;
    color: red;
    opacity: 1;
    cursor: pointer;
  
}

.backgroundSliderMobile-homeLinds{
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(133, 133, 133, 0),rgba(133, 133, 133, 0), rgb(224, 224, 224));
    top: 0px;
    color: red;
    opacity: 1;
    cursor: pointer;
}

.backgroundSliderMobile-homeStrada{
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(133, 133, 133, 0),rgba(133, 133, 133, 0), rgb(224, 224, 224));
    top: 0px;
    color: red;
    opacity: 1;
    cursor: pointer;
}

.textSliderMobile-home{
    position: absolute;
    margin: 0px 25px;
    padding-top: 225px;
    z-index: 1;
    color: black;
    text-decoration: none;
    width: 85%;
    height: 100%;
}

.textSliderMobile-home:hover{
    color: black;
    text-decoration: none;
}

.textSliderMobile-home div{
    margin-top: 1px;
}

.marginproject{
    margin-top: 140px;
}

.fontprojecthome{
    font-size: 46px;
    font-weight: 400;
}

.marginhome{
    padding-top: 125px;
}

.marginbuttonhome {
    margin-top: 63px;
}

.fontAbout {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.025cm;
    line-height: 17px;
}

.fontHeadingHome {
    font-size: 36px;
    line-height: 54.81px;
    margin-top: 25px;
    font-weight: 300;
    color: black;
}

.fontHomeBody {
    font-size: 18px;
    margin-top: 25px;
    font-weight: 300;
    color: #8F8F8F;
    line-height: 180%;
    letter-spacing: 0.025cm;
}

.fontReadMore {
    font-weight: 400;
    margin-top: 30px;
    font-size: 16px;
    color: black;
}

/* Image Hover Home */

.imagehoverproject{
    margin-top: 60px;
    height: 700px;
}

.listhover{
    height: 700px;
}

.borderhover{
    height: 700px;
}

.fonthoverheading{
    font-size: 16px;
    font-weight: 700;
}


.fonthoverbody{
    font-size: 28px;
    font-weight: 300;
    margin-top: 10px;
}


.projectcardheight{
    height: 200px;
    width: 100%;
}

/* Large */
@media (max-width:1200px) {
    
    
}

/* Medium */
@media (max-width:768px) {

    .hoverHomeMobile {
        display: none;
    }

    .fontprojecthome{
        font-size: 30px;
        font-weight: 400;
    }

    .bgsliderhome{
        width: 100%;
        height: 480px;
        object-fit: cover;
        z-index: -1;
    
    }

    .marginhome{
        padding-top: 80px;
    }

    .marginproject{
        margin-top: 100px;
    }

    .fontAbout {
        font-size: 11px;
        font-weight: 700;
        letter-spacing: 0.025cm;
        line-height: 17px;
    }

    .fontHeadingHome {
        font-size: 26px;
        line-height: 39px;
        margin-top: 20px;
        font-weight: 300;
        color: black;
    }
    
    .fontHomeBody {
        font-size: 12px;
        margin-top: 15px;
        font-weight: 400;
        color: #8F8F8F;
        line-height: 20.4px;
        letter-spacing: 0.025cm;
    }

    .fontReadMore {
        font-weight: 400;
        margin-top: 30px;
        font-size: 11px;
        color: black;
    }

    .marginbuttonhome {
        margin-top: 50px;
    }

    /* Image Hover Home */

    .imagehoverproject{
        margin-top: 50px;
        height: 374px;
    }

    .listhover{
        height: 374px;
    }

    .borderhover{
        height: 374px;
    }

    .fonthoverheading{
        font-family: "Lato", sans-serif;
        font-size: 12px;
        font-weight: 700;
        margin-left: 10px;
    }

    .fonthoverbody{
        font-size: 20px;
        font-weight: 300;
        margin-top: 8px;
        margin-left: 10px;
    }

    .projectcardheight{
        height: 100px;
        width: 100%;
    }

    
    
}


/* Small */
@media (max-width:576px) {
    .hoverHomeMobile {
        display: block;
        position: relative;
        margin-top: 60px;
    }

    .hoverHomeNonMobile{
        display: none;
    }

    .bgsliderhome{
        position:relative;
        width: 100%;
        height: 300px;
        z-index: -2 !important;
    }

    .marginproject{
        margin-top: 60px;
    }

    .fontprojecthome{
        font-size: 40px;
        font-weight: 400;
    }

    .fontAbout {
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 0.025cm;
        line-height: 17px;
    }

    .fontHeadingHome {
        font-size: 26px;
        line-height: 39px;
        margin-top: 20px;
        font-weight: 300;
        color: black;
    }

    .fontHomeBody {
        font-size: 15px;
        margin-top: 15px;
        font-weight: 400;
        color: #8F8F8F;
        line-height: 25.5px;
        letter-spacing: 2.5%;
    }

    /* Image Hover */

    .imagehoverproject{
        margin-top: 60px;
        height: 300px;
    }
    
    .listhover{
        height: 300px;
    }

    .borderhover{
        height: 300px;
        border: 1px solid white;
    }

    .fonthoverheading{
    font-size: 13px;
    font-weight: 700;
    margin-left: 5px;
}

    .fonthoverbody{
        font-size: 15px;
        font-weight: 300;
        margin-top: 10px;
        margin-left: 5px;
    }

    .marginhome{
        padding-top: 60px;
    }

    .projectcardheight{
        height: 120px;
        width: 100%;
    }
    
    
}